import Seo from "../../components/seo"
import React, { useEffect, useState, useRef, FunctionComponent } from "react"
import HeroScribble from "../../assets/heroScribble"
import CatchPhraseBlue from "../../images/cp-blue.png"
import CatchPhraseYellow from "../../images/cp-yellow.png"
import CatchPhraseRed from "../../images/cp-red.png"
import CatchPhrasePurple from "../../images/cp-purple.png"
import CatchPhraseOrange from "../../images/cp-orange.png"
import CatchPhraseGreen from "../../images/cp-green.png"
import HarshitMugshot from "../../images/harshit.png"
import NehalMugshot from "../../images/nehal.png"
import RuchaMugshot from "../../images/rucha.png"
import RightArrow from "../../assets/rightArrow"
import TTALVideo from "../../assets/ttal.mp4"

import { generateRandomNumber } from "../../utils/generateRandomNumber"
import Popover from "../../components/Popover"
import { MonitorIcon } from "../../assets/monitorIcon"
import { ArggIcon, CrossIcon, ShareIcon } from "../../assets/modalIcons"
import { initAmplitude, sendAmplitudeData } from "../../utils/amplitude"
import { AmplitudeEvents } from "../../types/amplitudeEvents"
import {
  handleMouseDownEvent,
  handleMouseUpEvent,
} from "../../utils/buttonClickScaleEffect"

import Footer from "../../components/Footer"
import LogoHeader from "../../components/LogoHeader"
import ColorSelector from "../../components/ColorSelector"
import "./ttal.css"
import FloatingBannerContainer from "../../components/FloatingBannerContainer"
import MarketingFloatingBanner from "../../components/MarketingFloatingBanner"
import { getPSTTime } from "../../utils/getPSTTime"
import { loadExternalScripts } from "../../utils/loadExternalScript"
import DollarSymbol from "../../assets/dollar"
import Loop from "../../assets/loop"
import ThursdayLogo from "../../assets/thursdayLogo"
import PersonsSVG from "../../assets/personsSVG"
import VideoSVG from "../../assets/videoSVG"
import { StarSVG } from "../../assets/starSVG"
import {
  limitedTimeDealEndDate,
  mixers,
  TTAL_DESCRIPTION_METADATA,
  TTAL_TITLE_METADATA,
} from "../../utils/constants"
import WindowIcon from "../../assets/windowIcon"
import { handleContactUs } from ".."
import CurvedUnderline from "../../assets/curvedUnderLine"
import Slash from "../../assets/slashSVG"
import PaymentSection from "../../components/PaymentSection"
import FreeMixerModal from "../../components/FreeMixerModal"
import { utmCookieRequest } from "../../utils/handleUTFTracking"
import FaqSection from "../../components/FaqSection"
interface OpacityHandlerInterface {
  highestOpacityTxtIndex: number
  allElements: ChildNode[] | any
}

const TtalPage: FunctionComponent = () => {
  const [catchPhraseIndex, setCatchPhraseIndex] = useState<number>(0)
  const [selectedVideoOptionIndex, setSelectedVideoOptionIndex] = useState(0)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [showBanner, setShowBanner] = useState(false)
  const [isJSLoaded, setIsJSLoaded] = useState(true)
  const [showFreeMixerModal, setShowFreeMixerModal] = React.useState(false)
  const [ltdTimer, setLtdTimer] = useState([])
  const [showSuperDeal, setSuperDeal] = useState(false)

  const freeSocialHandler = () => {
    setShowFreeMixerModal(true)
  }

  const startTimer = () => {
    const setTimer = setInterval(function () {
      const now = new Date().getTime()
      const distance = limitedTimeDealEndDate - now

      var days = Math.floor(distance / (1000 * 60 * 60 * 24))
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      var seconds = Math.floor((distance % (1000 * 60)) / 1000)

      if (days <= 0 && hours <= 0 && minutes <= 0) {
        setSuperDeal(false)
        clearInterval(setTimer)
      }
      let currentTime = []
      currentTime.push(days, hours, minutes, seconds)
      setLtdTimer(currentTime)
    }, 1000)
  }

  React.useEffect(() => {
    const now = new Date().getTime()
    const distance = limitedTimeDealEndDate - now
    if (distance > 0) {
      setSuperDeal(true)
      startTimer()
    }
  }, [])

  const [displayMixerInfo, setDisplayMixerInfo] = React.useState([
    "hide-card",
    "hide-card",
    "hide-card",
  ])
  const vidTTALRef = useRef()

  const premiumPrice = "$19"

  const heroVideos = [{ ref: vidTTALRef, video: TTALVideo }]

  const handleCreateSocial = btnType => () => {
    const thursdayLink = process.env.THURSDAY_SIGNIN_URL
    const joinThursdayLink = `${thursdayLink}?auto=true&templateCode=aba81eeb-6275-4b2f-be80-3fee8082acf5`
    if (window?.innerWidth < 800) {
      // The body scroll should be disable when the modal is open
      const body = document.querySelector("body")
      body.style.overflow = "hidden"
      sendAmplitudeData(AmplitudeEvents.SHARE_MODAL_OPENED)
      setIsModalVisible(true)
    } else {
      sendAmplitudeData(btnType)
      window.location.href = joinThursdayLink
    }
  }

  const handleSignIn = btnType => () => {
    const thursdayLink = process.env.THURSDAY_SIGNIN_URL

    if (window?.innerWidth < 800) {
      // The body scroll should be disable when the modal is open
      const body = document.querySelector("body")
      body.style.overflow = "hidden"
      sendAmplitudeData(AmplitudeEvents.SHARE_MODAL_OPENED)
      setIsModalVisible(true)
    } else {
      sendAmplitudeData(btnType)
      // window?.open(thursdayLink, "_self")
      window.location.href = thursdayLink
    }
  }

  const handleGrabItNowLink = () => {
    sendAmplitudeData(AmplitudeEvents.CLICK_SUPER_DEAL_CARD)
    window.location.href = process.env.SUPER_DEAL_LINK
  }

  const handleThursdayRedirect = btnType => () => {
    sendAmplitudeData(btnType)
    window.location.href = "/"
  }

  const handleJointttal = () =>{
    const ttalEnvLink = process.env.THURSDAY_CREATE_SOCIAL_URL
    const ttallink = `${ttalEnvLink}?auto=true&templateCode=3f0b5ec9-a0e0-4d68-b3cb-4f5d80d13a38`
    if (window?.innerWidth < 800) {
      // The body scroll should be disable when the modal is open
      const body = document.querySelector("body")
      body.style.overflow = "hidden"
      sendAmplitudeData(AmplitudeEvents.CREATE_TTAL_FROM_UNIQUEMIXER_PAGE)
      setIsModalVisible(true)
    } else {
      window.location.href = ttallink
    }
  }

  const handleStartTtal = btnType => () => {
    const ttalEnvLink = process.env.THURSDAY_SIGNIN_URL
    const ttallink = `${ttalEnvLink}?auto=true&templateCode=3f0b5ec9-a0e0-4d68-b3cb-4f5d80d13a38`

    if (window?.innerWidth < 800) {
      // The body scroll should be disable when the modal is open
      const body = document.querySelector("body")
      body.style.overflow = "hidden"
      sendAmplitudeData(AmplitudeEvents.SHARE_MODAL_OPENED)
      setIsModalVisible(true)
    } else {
      sendAmplitudeData(btnType)
      window.location.href = ttallink
    }
  }

  // Cleanup needed change object to array
  const catchPhraseSectionInfo: any = {
    0: {
      bg: "var(--theme-orange)",
      phrase: <>Who’s the best liar in your team?</>,
      "btn-Txt": <>My buddy, Pinnochio</>,
      "btn-class": "btn-orangeTxt",
    },
    1: {
      bg: "var(--theme-green)",
      phrase: <>Are you Sherlock enough?</>,
      "btn-Txt": <>Maybe a Watson</>,
      "btn-class": "btn-greenTxt",
    },
    2: {
      bg: "var(--theme-purple)",
      phrase: <>Can the real liar please standup?</>,
      "btn-Txt": <>Maybe later</>,
      "btn-class": "btn-purpleTxt",
    },
    3: {
      bg: "var(--theme-yellow)",
      phrase: <>PSA: this game can make you a serial liar</>,
      "btn-Txt": <>I’d like to try</>,
      "btn-class": "btn-yellowTxt",
    },

    4: {
      bg: "var(--theme-red)",
      phrase: <>2 truths and a damn good lie</>,
      "btn-Txt": <>Ready for deception</>,
      "btn-class": "btn-redTxt",
    },

    5: {
      bg: "var(--theme-lightBlue)",
      phrase: <>Do you have a poker face?</>,
      "btn-Txt": <>Better than Squidward</>,
      "btn-class": "btn-lightBlueTxt",
    },
  }

  useEffect(() => {
    setIsJSLoaded(false)
    utmCookieRequest()
  }, [])

  useEffect(() => {
    const vidEle: any = document.getElementsByClassName("hero-video")[0]

    /* Opacity handler for the video options */
    const opacityHandler = ({
      highestOpacityTxtIndex,
      allElements,
    }: OpacityHandlerInterface) => {
      const eleLength = allElements.length
      let count = 1

      // Set the center element opacity 100%
      allElements[highestOpacityTxtIndex].style.opacity = 1

      // Decrease the opacity of the other elements spreading outward from the center index
      while (
        highestOpacityTxtIndex - count >= 0 ||
        highestOpacityTxtIndex + count < eleLength
      ) {
        const lowerIndex = highestOpacityTxtIndex - count
        const higherIndex = highestOpacityTxtIndex + count

        if (allElements?.[lowerIndex]) {
          allElements[lowerIndex].style.opacity = `${0.9 - 0.15 * count}`
        }

        if (allElements?.[higherIndex]) {
          allElements[higherIndex].style.opacity = `${0.9 - 0.15 * count}`
        }

        count++
      }

      return
    }

    /* Scroll handler for the light blue section */
    const scrollHandler = () => {
      const advantagesSection = document.querySelector(
        ".advantages-section div"
      )
      const windowInnerHeight = window?.innerHeight
      const advantageTextHeight =
        advantagesSection?.childNodes[0]?.getBoundingClientRect()?.height

      const windowCenterRangerLower =
        (windowInnerHeight - advantageTextHeight) / 2
      const windowCenterRangerHigher =
        (windowInnerHeight + advantageTextHeight) / 2

      advantagesSection?.childNodes?.forEach((ele, index) => {
        let eleCoordinates = ele?.getBoundingClientRect()

        if (
          windowCenterRangerLower <= eleCoordinates.top &&
          windowCenterRangerHigher >= eleCoordinates.top
        ) {
          opacityHandler({
            highestOpacityTxtIndex: index,
            allElements: Array.from(advantagesSection.childNodes),
          })
        }
      })
    }

    /* Reset video container height when the video is resized */
    const resetVideoContainerHeight = () => {
      document.getElementsByClassName("floating-video")[0].style.minHeight = `${
        vidEle?.getBoundingClientRect()?.height + 5
      }px`
    }

    /* When the video is loaded reset the video container height.
     To avoid shift of the other content when the next videos are loaded  */
    const handleVideoLoaded = () => {
      if (vidEle?.readyState >= 3) {
        vidEle?.play()
        resetVideoContainerHeight()
      }
    }

    vidEle.addEventListener("loadeddata", handleVideoLoaded)
    document.addEventListener("scroll", scrollHandler)
    window?.addEventListener("resize", resetVideoContainerHeight)

    return () => {
      document.removeEventListener("scroll", scrollHandler)
      vidEle.removeEventListener("loadeddata", handleVideoLoaded)
      window?.removeEventListener("scroll", resetVideoContainerHeight)
    }
  }, [])

  useEffect(() => {
    const randomNumber = generateRandomNumber({ max: 5, min: 0 })
    const isParamRef = new URLSearchParams(window?.location?.search || "").get(
      "ref"
    )
    const extraParams: { "ref-url"?: string } = {}
    const currentPstTime = getPSTTime([])

    const day = currentPstTime?.getDate()
    const month = currentPstTime?.getMonth()

    const isRedirectedFromPH = isParamRef === "producthunt"

    const isLaunchWeek = month === 1 && day > 15 && day < 22

    const checkIfWithinLaunchWeek = isLaunchWeek

    if (document && document.referrer) {
      extraParams["ref-url"] = document.referrer
    }

    setCatchPhraseIndex(randomNumber)

    initAmplitude()
    sendAmplitudeData(AmplitudeEvents.TTAL_PAGE_VISITED, extraParams)

    if (isRedirectedFromPH || checkIfWithinLaunchWeek) {
      setShowBanner(true)
    }

    if (isParamRef === "producthunt") {
      sendAmplitudeData(AmplitudeEvents.REDIRECT_FROM_PRODUCTHUNT)
    }
  }, [])

  const closeModal = () => {
    // The body scroll should be disable when the modal is open
    const body = document.querySelector("body")
    body.style.overflow = "auto"

    setIsModalVisible(false)
  }

  const handleEventBubbling = ev => {
    ev.stopPropagation()
  }

  // Move to utils
  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: TTAL_TITLE_METADATA,
          text: TTAL_DESCRIPTION_METADATA,
          url: window?.location?.href,
        })
        .then(() => console.log("Successful share"))
        .catch(error => console.log("Error sharing the link", error))
    } else {
      console.log("Share not supported on this browser, do it the old way.")
    }
  }

  const catchPhraseBtnClick = () => {
    const nextSectionIndex =
      catchPhraseIndex === Object.values(catchPhraseSectionInfo).length - 1
        ? 0
        : catchPhraseIndex + 1

    setCatchPhraseIndex(nextSectionIndex)
  }

  return (
    <>
      <Seo
        title={TTAL_TITLE_METADATA}
        description={TTAL_DESCRIPTION_METADATA}
        canonical="https://thursday.social/two-truths-and-a-lie/"
      />

      {isModalVisible && (
        <div className="modal-bg" onClick={closeModal}>
          <div className="modal" onClick={handleEventBubbling}>
            <div className="cross" onClick={closeModal}>
              <CrossIcon />
            </div>
            <div className="argg-icon">
              <ArggIcon />
            </div>

            <p className="user-msg">
              Thursday is available only on desktop, for now
            </p>

            <p className="create-social-msg">Create a social from desktop</p>

            <button
              className="share-btn"
              onClick={handleShare}
              onMouseDown={handleMouseDownEvent}
              onMouseUp={handleMouseUpEvent}
            >
              <ShareIcon />
              &nbsp; Share
            </button>
          </div>
        </div>
      )}

      <div className="ttal-hero-section ttal-bg-green">
        <div className="border"></div>
        <LogoHeader headerData={mixers} logo="ttalLogo" isLink={true} />

        <div className="ttal-heading">
          <div className="h1Text ttal-hero-text">
            Do you know your teammates{" "}
            <div className="ttal-hero-mugshots">
              <div className="ttal-hero-mugshot">
                <img src={HarshitMugshot} alt="Mugshot with Harshit's image" />
              </div>
              <div className="ttal-hero-mugshot">
                <Popover content="LIAR!" styleClass="red" />
                <img src={NehalMugshot} alt="Mugshot with Nehal's image" />
              </div>
              <div className="ttal-hero-mugshot">
                <img src={RuchaMugshot} alt="Mugshot with Rucha's image" />
              </div>
            </div>
            well enough?
          </div>
        </div>

        <div className="thursday-logo-subheading">
          <div className="ttal-hero-subText h3Text">
            A new team-building mixer, by
          </div>

          <div
            className="logo-wrapper"
            onClick={handleThursdayRedirect(
              AmplitudeEvents.VISIT_MARKETING_PAGE_FROM_THURSDAY_LOGO
            )}
          >
            <ThursdayLogo color="var(--theme-green)" />
          </div>
        </div>

        <div className="desktop-only-msg">
          <MonitorIcon />
          <p>Only on desktop, for now</p>
        </div>

        <div className="hero-btn-container">
          {isJSLoaded ? (
            <a
              onClick={handleJointttal}
              className="theme-btn ttal-green"
              href={process.env.TTAL_CREATE_SOCIAL_LINK}
            >
              Start 2 truths & a lie
            </a>
          ) : (
            <button
              onClick={handleJointttal}
              className="theme-btn ttal-green"
              onMouseDown={handleMouseDownEvent}
              onMouseUp={handleMouseUpEvent}
            >
              Start 2 truths & a lie
            </button>
          )}

          {/* <HeroScribble /> */}
        </div>
      </div>
      <div className="ttal-floating-video-container ttal-gradient">
        <div className="floating-video-main">
          <div className="floating-video">
            {heroVideos.map((val, ind) => (
              <video
                key={ind + "heroVideo"}
                ref={val.ref}
                src={val.video}
                autoPlay={true}
                loop
                playsInline
                style={{
                  display: ind === selectedVideoOptionIndex ? "block" : "none",
                }}
                muted={true}
                className="hero-video"
              />
            ))}
          </div>
        </div>
      </div>

      <div className="ttal-features-section">
        <div className="ttal-feature-container">
          <div className="ttal-feature-content">
            <div className="feature-logo-wrapper">
              {" "}
              <PersonsSVG />
            </div>

            <h3 className="feature-title h2Text">Team-building</h3>

            <p className="ttal-feature-description body-txt">
              2 truths & a lie is designed to be played in teams. Optimised for
              max conversations & total fun!
            </p>

            <button
              onClick={handleStartTtal(
                AmplitudeEvents.CREATE_TTAL_FROM_FEATURE_BTN1
              )}
              className="txtBtn-with-arrow-section txt-btn body-txt purple-txtBtn"
            >
              <p>Build your team culture</p>
              <RightArrow color="var(--theme-purple)" />
            </button>
          </div>
        </div>

        <div className="ttal-feature-container">
          <div className="ttal-feature-content ">
            <div className="feature-logo-wrapper">
              <VideoSVG />
            </div>

            <h3 className="feature-title h2Text">Audio + Video</h3>

            <p className="ttal-feature-description body-txt">
              We have audio and video built right inside Thursday. Drop your
              boring socials on Zooom.
            </p>

            <button
              onClick={handleStartTtal(
                AmplitudeEvents.CREATE_TTAL_FROM_FEATURE_BTN2
              )}
              className="txtBtn-with-arrow-section txt-btn body-txt red-txtBtn"
            >
              <p>Try it out</p>

              <RightArrow color="var(--theme-red)" />
            </button>
          </div>
        </div>

        <div className="ttal-feature-container">
          <div className="ttal-feature-content">
            <div className="feature-logo-wrapper">
              {" "}
              <StarSVG />
            </div>

            <h3 className="feature-title h2Text">4.2 Avg rating</h3>

            <p className="ttal-feature-description body-txt">
              All our mixers are 4+ rating from users, and constantly rising. We
              bet your team will love it.
            </p>

            <button
              onClick={handleCreateSocial(
                AmplitudeEvents.CREATE_SOCIAL_FROM_TTAL_FEATURE_BTN3
              )}
              className="txtBtn-with-arrow-section txt-btn body-txt yellow-txtBtn"
            >
              <p>See all mixers</p>

              <RightArrow color="var(--theme-yellow)" />
            </button>
          </div>
        </div>
      </div>

      <FreeMixerModal
        show={showFreeMixerModal}
        setShow={setShowFreeMixerModal}
        handleSignIn={handleSignIn}
        handleCreateSocial={handleCreateSocial}
      />
      {/* <PaymentSection
        handleStart14DaysTrial={handleStart14DaysTrial}
        handleCreateSocial={handleCreateSocial}
      /> */}

      <div className="advantages-section">
        <div className="h1Text">
          <div>No installing.</div>

          <div>No signup.</div>

          <div>No ads.</div>

          <div>No work.</div>

          <div>No boring.</div>
        </div>
      </div>

      <FaqSection />

      <div
        className="catchPhrase-section"
        style={{
          background: `${catchPhraseSectionInfo?.[`${catchPhraseIndex}`]?.bg}`,
        }}
      >
        <img
          className="background-blue-banner"
          src={CatchPhraseBlue}
          alt="background-blue-banner"
        />

        <img
          className="background-yellow-banner"
          src={CatchPhraseYellow}
          alt="background-yellow-banner"
        />

        <img
          className="background-red-banner"
          src={CatchPhraseRed}
          alt="background-red-banner"
        />

        <img
          className="background-purple-banner"
          src={CatchPhrasePurple}
          alt="background-purple-banner"
        />

        <img
          className="background-orange-banner"
          src={CatchPhraseOrange}
          alt="background-orange-banner"
        />

        <img
          className="background-green-banner"
          src={CatchPhraseGreen}
          alt="background-green-banner"
        />

        <div className="catchPhrase h1Text">
          {catchPhraseSectionInfo?.[`${catchPhraseIndex}`]?.phrase}
        </div>

        <button
          onClick={catchPhraseBtnClick}
          onMouseDown={handleMouseDownEvent}
          onMouseUp={handleMouseUpEvent}
          className={
            "theme-btn " +
            catchPhraseSectionInfo?.[`${catchPhraseIndex}`]?.["btn-class"]
          }
        >
          {catchPhraseSectionInfo?.[`${catchPhraseIndex}`]?.["btn-Txt"]}
        </button>
      </div>

      {showBanner && (
        <FloatingBannerContainer>
          <MarketingFloatingBanner></MarketingFloatingBanner>
        </FloatingBannerContainer>
      )}
      <ColorSelector clickHandler={setCatchPhraseIndex} />

      <Footer />
    </>
  )
}

export default TtalPage
